import { httpClient, type AuthHeaders } from "@cumulus/http";
import { PurchaseOrder } from "@/repository/purchase-order/model/PurchaseOrder";

class PurchaseApi {
  private uri = "";

  constructor() {
    this.uri =
      typeof import.meta.env.VITE_APP_API_PRODUCT != "undefined" &&
      import.meta.env.VITE_APP_API_PRODUCT
        ? import.meta.env.VITE_APP_API_PRODUCT + "/purchase-orders"
        : `${import.meta.env.VITE_APP_API_URL as string}/purchase-orders`;
  }

  public async get(
    authHeaders: AuthHeaders,
    id: string
  ): Promise<PurchaseOrder> {
    return await httpClient(this.uri, authHeaders)
      .get(id)
      .then((response) => response.data);
  }
}

const purchaseApi = new PurchaseApi();
export { purchaseApi };
