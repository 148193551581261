import { httpClient, type AuthHeaders } from "@cumulus/http";
import { PurchaseOrderSearchRequest as PurchaseOrderSearchRequest } from "@/model/search/purchase-order-search/PurchaseOrderSearchRequest";
import { PurchaseOrderSearchReponse } from "@/model/search/purchase-order-search/PurchaseOrderSearchResponse";

class SearchApi {
  private uri = "";

  constructor() {
    this.uri =
      typeof import.meta.env.VITE_APP_API_SEARCH != "undefined" &&
      import.meta.env.VITE_APP_API_SEARCH
        ? import.meta.env.VITE_APP_API_SEARCH
        : `${import.meta.env.VITE_APP_API_URL as string}`;
  }

  //Purchase Order search (Good reception search) is the same as PurchaseOrder search in Purchase MF
  public async searchForPurchaseOrders(
    authHeaders: AuthHeaders,
    request: PurchaseOrderSearchRequest
  ): Promise<PurchaseOrderSearchReponse> {
    return await httpClient(this.uri, authHeaders)
      .post("/purchase-orders/search", request)
      .then(({ data }) => data);
  }
}

const searchApi = new SearchApi();
export { searchApi };
