import { GoodsReceptionLine } from "./GoodsReceptionLine";

export class GoodsReceptionRequest {
  public purchaseOrderId: string;
  public warehouseId: string;
  public goodsReceptionLines: GoodsReceptionLine[];
  public freightPrice: number;
  public currencyIso: string;
  public currencyExchangeRate: number;
  public currencyUnit: number;

  constructor(
    purchaseOrderId: string,
    freightPrice: number,
    goodsReceptionLines: GoodsReceptionLine[] = [],
    warehouseId: string,
    currencyIso: string,
    currencyExchangeRate: number,
    currencyUnit: number
  ) {
    this.purchaseOrderId = purchaseOrderId;
    this.freightPrice = freightPrice;
    this.goodsReceptionLines = goodsReceptionLines;
    this.warehouseId = warehouseId;
    this.currencyIso = currencyIso;
    this.currencyExchangeRate = currencyExchangeRate;
    this.currencyUnit = currencyUnit;
  }
}
