import { httpClient, AuthHeaders } from "@cumulus/http";
import { Currency } from "./model/Currency";

class CurrencyApi {
  private uri = "";

  constructor() {
    this.uri =
      import.meta.env.VITE_APP_API_FINANCE != undefined && import.meta.env.VITE_APP_API_FINANCE
        ? import.meta.env.VITE_APP_API_FINANCE + "/currencies"
        : `${import.meta.env.VITE_APP_API_URL as string}/currencies`;
  }

  public async getAll(authHeaders: AuthHeaders): Promise<Currency[]> {
    return await httpClient(this.uri, authHeaders)
      .get("/")
      .then(({ data }) => data);
  }
}

export const currencyApi = new CurrencyApi();
