import { PurchaseOrderLine } from "@/repository/purchase-order/model/PurchaseOrderLine";

export class PurchaseOrderLineToReceive extends PurchaseOrderLine {
  constructor() {
    super();
  }
  openQuantity = 0;
  quantityToReceive = 0;
  reference = "";
  purchasePrice = 0;
  receivedCost = 0;
}
