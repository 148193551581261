<template>
  <div class="mt-3">
    <FloatLabelInput
      id="purchase-order-reference"
      :label="t('purchase.purchase-reference.label')"
      v-model:value="purchaseOrderReferenceComputed"
      :selectAllOnFocus="true"
      :placeholder="t('placeholder.type', { property: t('purchase.purchase-reference.label').toLowerCase() })"
      dataTestId="purchase-order-reference"
      :disabled="true"
      class="p-disabled"
    />
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { useI18n } from "vue-i18n";

const { t } = useI18n();

const props = defineProps<{
  purchaseOrderReference: string;
}>();

const emit = defineEmits<{
  (e: "update:purchaseOrderReference", value: string): void;
}>();

const purchaseOrderReferenceComputed = computed<string>({
  get: () => {
    return props.purchaseOrderReference ?? "";
  },
  set: (value) => {
    emit("update:purchaseOrderReference", value);
  },
});
</script>
