<template>
  <div class="data-table-container w-full">
    <div class="card p-6">
      <ColumnOptionsMenu :label="t('purchase.search.quantity-order-lines')" :itemsList="items"></ColumnOptionsMenu>

      <DataTable
        ref="tablePurchaseOrderLines"
        :value="props.purchaseOrderLinesToReceive"
        stripedRows
        id="goods-reception-lines-table"
        class="c-datatable c-purchase-order-table"
        selectionMode="multiple"
        v-model:selection="selectedRows"
        v-model:expandedRows="expandedRows"
        :rowClass="lineStatusClass"
        @update:selection="onSelectionUpdated"
        @row-select="onRowSelected"
        @row-dblclick="onSelectOrderLine"
        data-testid="purchase-order-lines"
        @keydown="handleKeyPress($event)"
        @rowContextmenu="onRowContextMenu"
        :resizableColumns="true"
        columnResizeMode="fit"
        :reorderable-columns="true"
        @column-reorder="onColumnReorder"
        @column-resize-end="onColumnResizeEnd"
        :pt="{
          bodyRow: () => ({
            class: 'no-click',
          }),
        }"
        :key="renderKey as unknown as number"
      >
        <Column
          v-for="col of selectedColumnsComputed as unknown as DataTableColumnExt[]"
          :field="col.field"
          :header="col.header"
          :key="col.field"
          :columnKey="col.field"
          :class="col.class"
          :sortable="col.sortable"
          :expander="col.expander"
          :pt="{
            headerCell: {
              id: col.field,
              tabindex: -1,
            },
            columntitle: {
              class: {
                hidden: col.field === PurchaseOrderLineColumns.SelectPurchaseOrderLines,
              },
            },
            bodyCell: ({
              instance: {
                // @ts-ignore
                rowData,
              },
            }) => ({
              class: {
                clickable: true ? rowData.openQuantity > 0 : false,
              },
            }),
            rowToggleButton: ({
              instance: {
                // @ts-ignore
                rowData,
              },
            }) => ({
              class: {
                hidden: rowData.receivedQuantity === 0,
                clickable: true,
              },
            }),
          }"
          :style="col.size ? `width: ${col.size}px; max-width: ${col.size}px;` : ''"
        >
          <template v-if="col.field === PurchaseOrderLineColumns.SelectPurchaseOrderLines" #header>
            <Checkbox v-model="checkedAllRows" :binary="true" @click.stop />
          </template>
          <template v-if="col.field != PurchaseOrderLineColumns.ExpanderHandle" #body="{ data, field, index }">
            <template v-if="col.field === PurchaseOrderLineColumns.SelectPurchaseOrderLines">
              <Checkbox
                v-model="selectedRows"
                :value="data"
                data-testid="purchase-order-line-checkbox"
                @click.stop
                :class="{ hidden: data.openQuantity <= 0 }"
              />
            </template>
            <template v-else-if="col.field === PurchaseOrderLineColumns.PositionNumber">
              <span :data-testid="`purchase-order-line-position-number-${index}`">{{ data[field] }}</span>
            </template>
            <template v-else-if="col.field === PurchaseOrderLineColumns.ProductNumber">
              <span :data-testid="`purchase-order-line-product-number-${index}`">{{ data.product.productNumber }}</span>
            </template>
            <template v-else-if="col.field === PurchaseOrderLineColumns.SupplierProductNumber">
              <span :data-testid="`supplier-product-number-${index}`">{{ data.product.supplierProductNumber }}</span>
            </template>
            <template v-else-if="col.field === PurchaseOrderLineColumns.Gtin">
              <span :data-testid="`purchase-order-line-gtin-${index}`">{{ data.product.gtin }}</span>
            </template>
            <template v-else-if="col.field === PurchaseOrderLineColumns.ProductName">
              <span :data-testid="`purchase-order-line-product-name-${index}`">{{ data.product.name }}</span>
            </template>
            <template v-else-if="col.field === PurchaseOrderLineColumns.Quantity"
              ><span :data-testid="`purchase-order-line-quantity-${index}`">{{ data.quantity }}</span>
            </template>
            <template v-else-if="col.field === PurchaseOrderLineColumns.ReceivedQuantity">
              <span :data-testid="`purchase-order-line-received-${index}`">{{ data[field] }}</span>
            </template>
            <template v-else-if="col.field === PurchaseOrderLineColumns.EstimatedArrivalDate">
              <div class="text-center">
                {{ d(data.estimatedArrivalDate) }}
              </div>
            </template>
            <template v-else-if="col.field === PurchaseOrderLineColumns.QuantityToReceive">
              <div class="flex justify-center w-full">
                <PurchaseOrderToReceiveLineInput
                  v-if="editingRowIndex === index"
                  :id="`quantity-to-receive-input-${index}`"
                  :data-testid="`quantity-to-receive-input-${index}`"
                  :max="data.quantity === undefined ? 0 : data.quantity - data.receivedQuantity"
                  :min="0"
                  :value="data[field] ?? 0"
                  :minValue="1"
                  :allowEmpty="false"
                  class="m-2"
                  inputClass="w-14 text-right"
                  @update:modelValue="updateQuantityToReceive($event, data)"
                  :disabled="data.openQuantity === undefined || data.openQuantity <= 0"
                  @keydown.enter.prevent="stopEditRow(index)"
                  @keydown.esc.prevent="stopEditRow(index)"
                  :setFocus="true"
                />

                <span :data-testid="`quantity-to-receive-input-${index}`" v-if="editingRowIndex !== index" data>{{
                  data[field]
                }}</span>
              </div>
            </template>
            <template v-if="col.field === PurchaseOrderLineColumns.Reference">
              <div class="flex justify-center w-full">
                <InputText
                  v-if="editingRowIndex === index"
                  :id="`reference-input-${index}`"
                  v-model="data.reference"
                  :data-testid="`reference-input-${index}`"
                  class="text-center w-full m-2"
                  inputClass="w-full"
                  @update:modelValue="updateReferenceToReceive($event, data)"
                  :disabled="data.openQuantity <= 0"
                  @keydown.enter.prevent="stopEditRow(index)"
                  @keydown.esc.prevent="stopEditRow(index)"
                  :setFocus="true"
                />
                <span :data-testid="`reference-input-${index}`" v-if="editingRowIndex !== index" data>{{
                  data[field]
                }}</span>
              </div>
            </template>
            <template v-else-if="col.field === PurchaseOrderLineColumns.PurchasePrice">
              <div class="flex justify-center w-full">
                <PurchaseOrderToReceiveLineInput
                  v-if="editingRowIndex === index"
                  :id="`cost-per-unit-input-${index}`"
                  :data-testid="`cost-per-unit-input-${index}`"
                  v-model="data.purchasePrice"
                  class="w-full m-2"
                  inputClass="text-right w-full"
                  :min-fraction-digits="2"
                  :max-fraction-digits="2"
                  :allow-empty="false"
                  :disabled="data.quantityToReceive <= 0"
                  mode="currency"
                  currencyDisplay="narrowSymbol"
                  :currency="props.purchaseCurrencyIso"
                  @update:modelValue="onCostChangedInPurchaseLine(data)"
                  :value="data[field]"
                  @keydown.enter.prevent="stopEditRow(index)"
                  @keydown.esc.prevent="stopEditRow(index)"
                />
                <span :data-testid="`cost-per-unit-input-${index}`" v-if="editingRowIndex !== index" data>{{
                  data[field]
                }}</span>
              </div>
            </template>
            <template v-else-if="col.field === PurchaseOrderLineColumns.ReceivedCost">
              <span :data-testid="`line-price-currency-${index}`">{{
                n(data.receivedCost, "currency", props.clientCurrencyIso)
              }}</span>
            </template>

            <template v-else-if="col.field === PurchaseOrderLineColumns.SumLine">
              <span :data-testid="`line-total-cost-${index}`">{{
                n(data.sumLine, "currency", props.clientCurrencyIso)
              }}</span>
            </template>
            <template v-else-if="col.field === PurchaseOrderLineColumns.EditHandle">
              <div
                v-if="editingRowIndex !== index"
                class="c-purchase-orderline-select"
                :data-testid="`purchase-orderline-buttons-${index}`"
              >
                <i
                  role="button"
                  class="pi pi-ellipsis-h c-context-menu-button"
                  @click="(e) => openEllipsisContextMenu(e, data, index)"
                  :data-testid="`context-menu-button-${index}`"
                >
                </i>
              </div>

              <div v-else class="c-purchase-orderline-edit">
                <i
                  role="button"
                  class="pi pi-check c-purchase-orderline-close-button"
                  @click="stopEditRow(-1)"
                  data-testid="purchase-order-line-close"
                  :tabindex="editingRowIndex === index ? 0 : -1"
                >
                </i>
              </div>
            </template>
          </template>
        </Column>
        <template #expansion="slotProps">
          <GoodsReceptionHistory
            :goodsReceptions="getGoodsReceptionsForPurchaseOrderLineToReceiveId(slotProps.data.id)"
            :purchaseOrderLineId="slotProps.data.id"
          />
        </template>
        <template #empty>
          <div class="c-table-empty">{{ t("purchase.empty-list") }}</div>
        </template>
      </DataTable>
      <RowContextMenu
        :showDeleteOption="allowEdit && purchaseOrderStatus !== PurchaseOrderStatus.PartiallyReceived"
        ref="ellipsisContextMenuRef"
        @openProductInfo="openProductInfoDialog(selectedRow.product.id)"
        @selectRow="selectRow(selectedRowIndex)"
        data-testId="row-context-menu"
      />
    </div>
    <ColumnChooser
      v-model:visibleDialog="visible"
      v-model:selectedColumns="selectedColumnsComputed"
      :columns="filteredColumns"
      :label="t('common.reset')"
      :onSelectAllChange="onSelectAllChange"
      :selectAll="selectAll"
      @resetColumns="resetColumns"
      @columnSelected="onColumnSelected"
      @columnUnselected="onColumnUnselected"
    />
    <ProductInfoDialog v-if="showDialog" v-model:showDialog="showDialog" :productId="selectedRow.product.id" />

    <small
      class="p-error"
      v-if="val.purchaseOrderLinesToReceive.$error"
      data-testid="purchase-order-line-to-receive-error"
    >
      {{ val.purchaseOrderLinesToReceive.$errors[0].$message }}
    </small>
  </div>
</template>
<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { PurchaseOrderLineToReceive } from "@/repository/goods-reception/model/PurchaseOrderLineToReceive";
import { computed, ref, watch } from "vue";
import useVuelidate from "@vuelidate/core";

import { required, minLength, helpers } from "@vuelidate/validators";
import {
  DataTableRowContextMenuEvent,
  DataTableRowDoubleClickEvent,
  DataTableRowSelectEvent,
  DataTableSelectAllChangeEvent,
} from "primevue/datatable";
import { PurchaseOrderLineColumns } from "./PurchaseOrderLineColumns";
import PurchaseOrderToReceiveLineInput from "./PurchaseOrderLineInputoReceive.vue";
import { ColumnChooser, useTablePreferences, ColumnOptionsMenu } from "@cumulus/components";
import type { DataTableColumnExt } from "@cumulus/components";
import { useAuth } from "@cumulus/event-bus";
import { PurchaseOrderStatus } from "@/model/search/purchase-order-search/PurchaseOrderStatus";
import GoodsReceptionHistory from "@/components/GoodsReceptionHistory.vue";
import { GoodsReception } from "@/repository/goods-reception/model/GoodsReception";
import RowContextMenu from "@/components/RowContextMenu.vue";
import ProductInfoDialog from "@/components/product-info/ProductInfoDialog.vue";

const selectedRowIndex = ref<number>(0);
const tablePurchaseOrderLines = ref();
const previouslyFocusedRow = ref();
const focusedRow = ref();
const focusedRowIndex = ref<number>(0);
const ellipsisContextMenuRef = ref();
const selectedRow = ref<PurchaseOrderLineToReceive>(new PurchaseOrderLineToReceive());
const { t, n, d } = useI18n();
const visible = ref(false);
const expandedRows = ref([]);
const showDialog = ref<boolean>(false);

const props = defineProps<{
  purchaseOrderLinesToReceive: PurchaseOrderLineToReceive[];
  selectedLines: PurchaseOrderLineToReceive[];
  initialPurchaseOrderLinesToReceive: PurchaseOrderLineToReceive[];
  clientCurrencyIso: string;
  purchaseCurrencyIso: string;
  allowEdit: boolean;
  purchaseOrderStatus: PurchaseOrderStatus;
  goodsReceptions: GoodsReception[];
}>();

const emit = defineEmits<{
  (e: "quantityChanged"): void;
  (e: "costChangedInPurchaseLine", value: PurchaseOrderLineToReceive): void;
  (e: "selectionUpdated", lines: PurchaseOrderLineToReceive[]): void;
}>();

const focusFirstPurchaseOrderLinesToReceive = defineModel<boolean>("focusFirstPurchaseOrderLinesToReceive", {
  required: true,
});
const onFocusFirstPurchaseOrderLinesToReceive = () => {
  if (props.purchaseOrderLinesToReceive.length > 0) {
    const rows = tablePurchaseOrderLines.value.$el.querySelectorAll("tbody tr");
    for (const row of rows) {
      if (!row.classList.contains("c-line-done")) {
        row.focus();
        previouslyFocusedRow.value = row;
        const rowIndex = Array.from(rows).indexOf(row);
        selectedRow.value.product.id = props.purchaseOrderLinesToReceive[rowIndex].product.id;
        break;
      }
    }
  }
};

watch(
  () => focusFirstPurchaseOrderLinesToReceive.value,
  (active) => {
    if (active === true) {
      timeout(100).then(() => {
        onFocusFirstPurchaseOrderLinesToReceive();
        focusFirstPurchaseOrderLinesToReceive.value = false;
      });
    }
  },
  { deep: true }
);

const timeout = (ms: number) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

const clearSelectedRows = () => {
  selectedRows.value = [];
  emit("selectionUpdated", selectedRows.value);
};

defineExpose({
  clearSelectedRows,
});

const purchaseOrderLinesToReceiveColumns: DataTableColumnExt[] = [
  {
    field: "selectPurchaseOrderLines",
    header: "Checkbox",
    class: "w-12",
    sortable: false,
  },
  {
    field: "expanderHandle",
    header: "",
    sortable: false,
    class: "c-col-expanderHandle w-12",
    expander: true,
  },
  { field: "positionNumber", header: t("headers.posnr"), sortable: true },
  { field: "product.productNumber", header: t("purchase.product.number"), sortable: true },
  {
    field: "product.supplierProductNumber",
    header: t("purchase.product.supplier-product-number"),
    sortable: true,
  },
  { field: "product.gtin", header: t("purchase.product.gtin"), sortable: true },
  { field: "product.name", header: t("purchase.product.name"), sortable: true },
  {
    field: "quantity",
    header: t("headers.quantity-ordered"),
    sortable: true,
    class: "c-col-quantity py-0",
  },
  {
    field: "receivedQuantity",
    header: t("headers.received"),
    sortable: true,
    class: "py-0",
  },
  {
    field: "estimatedArrivalDate",
    header: t("purchase.product.eta-date"),
    sortable: true,
    class: "max-w-40 py-0",
  },
  {
    field: "quantityToReceive",
    header: t("headers.qty"),
    sortable: true,
    class: "max-w-16 py-0",
  },
  {
    field: "reference",
    header: t("goods-reception-history.reference"),
    sortable: true,
    class: "w-40 py-0",
  },

  {
    field: "purchasePrice",
    header: t("headers.purchase-price"),
    sortable: true,
    class: "w-32 py-0",
  },
  {
    field: "receivedCost",
    header: t("headers.received-cost"),
    sortable: true,
    class: "max-w-40 py-0",
  },

  { field: "sumLine", header: t("headers.line-total"), sortable: true, class: "text-right" },

  { field: "editHandle", header: "", sortable: false, class: "c-col-actions py-0 w-4 p-4" },
];

const { getUser } = useAuth();

const {
  selectedColumnsComputed,
  orderedColumns,
  renderKey,
  onColumnResizeEnd,
  onColumnSelected,
  onColumnUnselected,
  onColumnReorder,
  resetColumns,
} = useTablePreferences(
  "purchaseOrderLinesToReceive",
  purchaseOrderLinesToReceiveColumns,
  null,
  (await getUser()).getEmployee().id
);

const items = ref([
  {
    label: "Column chooser",
    icon: "pi pi-list c-default-button c-circular-icon",

    class: "c-column-chooser",
    command: () => {
      visible.value = true;
    },
  },
]);

const filteredColumns = computed(() => {
  if (props.purchaseOrderStatus && props.purchaseOrderStatus !== PurchaseOrderStatus.Open) {
    return orderedColumns.value.filter(
      (col) =>
        col.field !== PurchaseOrderLineColumns.PositionNumber &&
        col.field !== PurchaseOrderLineColumns.SupplierProductNumber &&
        col.field !== PurchaseOrderLineColumns.ProductNumber
    );
  } else {
    return orderedColumns.value.filter(
      (col) =>
        col.field !== PurchaseOrderLineColumns.ExpanderHandle &&
        col.field !== PurchaseOrderLineColumns.EditHandle &&
        col.field !== PurchaseOrderLineColumns.PositionNumber &&
        col.field !== PurchaseOrderLineColumns.SupplierProductNumber &&
        col.field !== PurchaseOrderLineColumns.ProductNumber
    );
  }
});

const selectAll = ref(false);
const onSelectAllChange = (event: DataTableSelectAllChangeEvent) => {
  selectAll.value = event.checked;
  selectedColumnsComputed.value = event.checked
    ? purchaseOrderLinesToReceiveColumns
    : purchaseOrderLinesToReceiveColumns.filter(
        (c) =>
          c.field === PurchaseOrderLineColumns.PositionNumber ||
          c.field === PurchaseOrderLineColumns.SupplierProductNumber ||
          c.field === PurchaseOrderLineColumns.ProductNumber ||
          c.field === PurchaseOrderLineColumns.EditHandle ||
          c.field === PurchaseOrderLineColumns.ExpanderHandle
      );
};

const getGoodsReceptionsForPurchaseOrderLineToReceiveId = (purchaseOrderId: string) =>
  props.goodsReceptions.filter((x: GoodsReception) =>
    x.goodsReceptionLines.some((y) => y.purchaseOrderLineId === purchaseOrderId)
  );

const selectedRows = ref<PurchaseOrderLineToReceive[]>([]);

const checkedAllRows = computed<boolean>({
  get: () =>
    selectedRows.value.length === props.purchaseOrderLinesToReceive.filter((row) => row.openQuantity > 0).length,
  set: (value) => {
    selectedRows.value = !value ? [] : props.purchaseOrderLinesToReceive.filter((row) => row.openQuantity > 0);
  },
});

const onCostChangedInPurchaseLine = (line: PurchaseOrderLineToReceive) => {
  emit("costChangedInPurchaseLine", line);
};

const lineIsModified = (line: PurchaseOrderLineToReceive) => {
  return (
    line.quantityToReceive !== 0 ||
    line.reference !== "" ||
    line.purchasePrice !== props.initialPurchaseOrderLinesToReceive.find((x) => x.id === line.id)?.purchasePrice
  );
};

const lineIsFullyReceived = (line: PurchaseOrderLineToReceive) => {
  return line.quantityToReceive === line.quantity || line.receivedQuantity + line.quantityToReceive >= line.quantity;
};

const lineStatusClass = (line: PurchaseOrderLineToReceive) => {
  if (props.selectedLines.some((x) => x.id === line.id)) return "";
  if (lineIsFullyReceived(line)) return "c-line-done";
  if (!lineIsModified(line)) return "";
  else return "c-line-modified";
};

const onSelectionUpdated = () => {
  console.log("onSelectionUpdated");
  selectedRows.value = selectedRows.value.filter((row) => row.openQuantity > 0);
  if (selectedRows.value.length === 0) {
    const focusedElement = document.activeElement;
    const rows = tablePurchaseOrderLines.value?.$el.querySelectorAll("tbody tr");

    if (rows) {
      rows.forEach((row: HTMLTableRowElement, index: number) => {
        if (row === focusedElement) {
          selectRow(index);
        }
      });
    }
  }
  emit("selectionUpdated", selectedRows.value);
};

const editingRowIndex = ref<number | null>(null);

const onRowSelected = (event: DataTableRowSelectEvent) => {
  if (editingRowIndex.value === event.index) return;

  if (event.originalEvent != null) {
    if (!(event.originalEvent instanceof KeyboardEvent)) {
      return;
    }
    if (event.originalEvent.key === "Enter" && event.data.openQuantity != 0) {
      selectRow(event.index);
      return;
    }
  }
};

const onSelectOrderLine = (event: DataTableRowDoubleClickEvent) => {
  selectRow(event.index);
};

const selectRow = (index: number) => {
  editingRowIndex.value = index;
};

const handleKeyPress = (event: KeyboardEvent) => {
  if (event.key === "ArrowUp" || event.key === "ArrowDown") {
    const focusedElement = document.activeElement;
    const rows = tablePurchaseOrderLines.value?.$el.querySelectorAll("tbody tr");

    if (rows) {
      rows.forEach((row: HTMLTableRowElement, index: number) => {
        if (row === focusedElement) {
          selectedRowIndex.value = index;
          setFocusedRow(index);
        }
      });
    }
  }
  if (event.key === "Escape") {
    const focusedElement = document.activeElement;
    const rows = tablePurchaseOrderLines.value?.$el.querySelectorAll("tbody tr");
    if (rows) {
      rows.forEach((row: HTMLTableRowElement, index: number) => {
        if (row === focusedElement) {
          const containsInput = row.querySelector("input") !== null;
          if (!containsInput) {
            selectedRowIndex.value = index;
            setFocusedRow(index);
            resetRowValues(index);
          }
        }
      });
    }
    event.stopPropagation();
  }
};

const resetRowValues = (index: number) => {
  const initialValues = props.initialPurchaseOrderLinesToReceive[index];
  const currentValues = props.purchaseOrderLinesToReceive[index];

  if (currentValues != initialValues) {
    Object.assign(currentValues, initialValues);
  }
};

const openProductInfoDialog = (productId: string) => {
  selectedRow.value.product.id = productId;
  showDialog.value = true;
};

const updateQuantityToReceive = async (
  value: number | undefined,
  purchaseOrderLineToReceive: PurchaseOrderLineToReceive
) => {
  if (value === undefined) {
    value = 0;
  }

  purchaseOrderLineToReceive.quantityToReceive = value;
  emit("quantityChanged");
};

const updateReferenceToReceive = async (
  value: string | undefined,
  purchaseOrderLineToReceive: PurchaseOrderLineToReceive
) => {
  purchaseOrderLineToReceive.reference = value !== undefined ? value : "";
};

const setFocusedRow = (index: number) => {
  previouslyFocusedRow.value = tablePurchaseOrderLines.value.$el.querySelector(`tbody tr:nth-child(${index + 1})`);
};

const onRowContextMenu = (event: DataTableRowContextMenuEvent) => {
  focusedRow.value = event.data;
  focusedRowIndex.value = event.index;
  setFocusedRow(event.index);
  ellipsisContextMenuRef.value.openMenu(event.originalEvent);
};

const openEllipsisContextMenu = (event: Event, data: PurchaseOrderLineToReceive, index: number) => {
  selectedRow.value = data;
  selectedRowIndex.value = index;
  setFocusedRow(index);
  ellipsisContextMenuRef.value.openMenu(event);
};

const stopEditRow = (index: number) => {
  editingRowIndex.value = null;

  if (tablePurchaseOrderLines.value && index > -1) {
    const rows = tablePurchaseOrderLines.value?.$el.querySelectorAll("tbody tr");
    const row = rows[index];

    row.classList.remove("c-line-modified");
    row.classList.remove("c-line-done");

    const css = lineStatusClass(props.purchaseOrderLinesToReceive[index]);
    if (css !== "") {
      row.classList.add(css);
    }

    row.focus();
    document.addEventListener(
      "keydown",
      (event) => {
        if (event.key === "Enter") {
          const nextRow = rows[index + 1];
          if (nextRow) {
            nextRow.focus();
            selectedRowIndex.value = index + 1;
            openFieldsOnRow(index + 1);
          }
        }
      },
      { once: true }
    );
  }
};

const openFieldsOnRow = (index: number) => {
  previouslyFocusedRow.value = tablePurchaseOrderLines.value.$el.querySelector(`tbody tr:nth-child(${index + 1})`);
  editingRowIndex.value = index;
};

watch(
  () => showDialog.value,
  () => {
    if (showDialog.value === false) {
      if (previouslyFocusedRow.value) {
        previouslyFocusedRow.value.focus();
      }
    }
  }
);

const rules = {
  purchaseOrderLinesToReceive: {
    required: helpers.withMessage(
      t("validations.at-least-one", { property: t("purchase.purchasing-line").toLowerCase() }),
      required
    ),
    minLength: minLength(1),
  },
};

const val = useVuelidate(rules, props);
</script>
<style>
.no-click {
  pointer-events: none;
}

.no-click .clickable {
  pointer-events: auto;
}
.c-line-done {
  opacity: 0.6;
}
</style>
