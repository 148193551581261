<template>
  <CumulusRightSidebar :showSidebar="showSidebar" :enableDragover="false" :sidebarWidth="260">
    <template v-slot:header>
      <div class="c-filter-header-container">
        <i class="pi pi-sliders-h"></i>
        <h1>{{ t("search.filter") }}</h1>
      </div>
    </template>
    <template v-slot:body>
      <div class="formgrid grid grid-cols-12 gap-4 mt-6">
        <div class="col-span-6 field text-center">
          <CumulusDatePicker
            v-model:date="fromDateComputed"
            :label="t('from-date')"
            dataTestId="c-filter-from-date"
            :debounceTime="400"
          />
        </div>
        <div class="col-span-6 field text-center">
          <CumulusDatePicker
            v-model:date="toDateComputed"
            :label="t('to-date')"
            dataTestId="c-filter-to-date"
            :debounceTime="400"
          />
        </div>
      </div>
      <hr class="mt-6 mb-6" />
      <div class="text-center">
        <PurchaseOrderStatusCheckboxList v-model:selectedStatuses="statusesComputed" />
      </div>
      <hr class="mt-6 mb-6" />
      <div class="field text-center">
        <SelectWarehouse
          v-model:selectedWarehouseIds="selectedWarehouseComputed"
          :warehouses="warehouses"
          dataTestId="c-warehouse-filter"
        />
      </div>
      <hr class="mt-6 mb-6" />
      <div class="field text-center">
        <ChipsInput
          v-model:values="supplierNumbersComputed"
          :label="t('supplier-number')"
          dataTestId="c-supplier-number-filter"
          inputId="supplier-number"
        />
      </div>
      <hr class="mt-6 mb-6" />
      <div class="field text-center">
        <ChipsInput
          v-model:values="productNumbersComputed"
          :label="t('product-number')"
          dataTestId="c-product-number-filter"
          inputId="product-number"
        />
      </div>
      <hr class="mt-6 mb-6" />
      <div class="field text-center">
        <ChipsInput
          v-model:values="purchaseOrderNumbersComputed"
          :label="t('purchase-order-number')"
          dataTestId="c-purchase-order-number-filter"
          inputId="purchase-order-number"
        />
      </div>
      <hr class="mt-6 mb-6" />
      <div class="field text-center">
        <ChipsInput
          v-model:values="supplierProductNumbersComputed"
          :label="t('supplier-product-number')"
          dataTestId="c-supplier-product-number-filter"
          inputId="supplier-product-number"
        />
      </div>
    </template>
  </CumulusRightSidebar>
</template>

<script setup lang="ts">
import { CumulusRightSidebar, CumulusDatePicker } from "@cumulus/components";
import { computed } from "vue";
import { useI18n } from "vue-i18n";

import { PurchaseOrderFilters } from "@/model/search/purchase-order-search/PurchaseOrderFilters";
import { Warehouse } from "@/repository/warehouse/model/Warehouse";
import SelectWarehouse from "../SelectWarehouse.vue";
import ChipsInput from "../ChipsInput.vue";
import PurchaseOrderStatusCheckboxList from "./PurchaseOrderStatusCheckboxList.vue";
import { PurchaseOrderStatus } from "@/model/search/purchase-order-search/PurchaseOrderStatus";

const props = defineProps<{
  showSidebar: boolean;
  filters: PurchaseOrderFilters;
  warehouses: Warehouse[];
}>();

const emit = defineEmits<{
  (event: "update:filters", value: PurchaseOrderFilters): void;
}>();

const { t } = useI18n();

const fromDateComputed = computed<string>({
  get: () => {
    return props.filters.fromDate ? props.filters.fromDate : "";
  },
  set: (fromDate) => {
    if (props.filters.fromDate === fromDate) return;

    const filters = { ...props.filters, fromDate: fromDate };
    emit("update:filters", filters);
  },
});

const toDateComputed = computed<string>({
  get: () => {
    return props.filters.toDate ? props.filters.toDate : "";
  },
  set: (toDate) => {
    if (props.filters.toDate === toDate) return;

    const filters = { ...props.filters, toDate: toDate };
    emit("update:filters", filters);
  },
});

const supplierNumbersComputed = computed<string[]>({
  get: () => props.filters.supplierNumbers,
  set: (value) => {
    const filters = { ...props.filters, supplierNumbers: value };
    emit("update:filters", filters);
  },
});

const supplierProductNumbersComputed = computed<string[]>({
  get: () => props.filters.supplierProductNumbers,
  set: (value) => {
    const filters = { ...props.filters, supplierProductNumbers: value };
    emit("update:filters", filters);
  },
});

const productNumbersComputed = computed<string[]>({
  get: () => props.filters.productNumbers,
  set: (value) => {
    const filters = { ...props.filters, productNumbers: value };
    emit("update:filters", filters);
  },
});

const purchaseOrderNumbersComputed = computed<string[]>({
  get: () => props.filters.purchaseOrderNumber,
  set: (value) => {
    const filters = { ...props.filters, purchaseOrderNumber: value };
    emit("update:filters", filters);
  },
});

const selectedWarehouseComputed = computed<string[]>({
  get: () => props.filters.warehouseIds,
  set: (value) => emit("update:filters", { ...props.filters, warehouseIds: value }),
});

const statusesComputed = computed<PurchaseOrderStatus[]>({
  get: () => props.filters.purchaseOrderStatuses,
  set: (value: PurchaseOrderStatus[]) => {
    const filters = { ...props.filters, purchaseOrderStatuses: value };
    emit("update:filters", filters);
  },
});
</script>

<style lang="scss" scoped>
.c-filter-header-container {
  justify-content: center;
  display: flex;
  align-items: center;
  h1 {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }
}
</style>
