<template>
  <div
    data-testid="receive-dialog-container"
    class="flex flex-col items-center"
  >
    <p>{{ t("add-reference-text") }}</p>
    <br />
    <p>{{ t("reference") }}:</p>
    <InputText
      data-testid="add-reference-input"
      class="w-6/12"
      v-model="reference"
    />
    <div class="flex justify-between w-full p-6">
      <Button
        data-testid="receive-dialog-cancel-button"
        icon="pi pi-times"
        class="p-button p-button-warning p-button-rounded"
        label="Cancel"
        @click="() => emit('cancel', false)"
      />
      <Button
        data-testid="receive-dialog-add-reference-button"
        icon="pi pi-check"
        class="p-button p-button-success p-button-rounded"
        label="Ok"
        @click="() => emit('receiveLines', reference)"
      />
    </div>
  </div>
</template>
<script setup lang="ts">
import { ref } from "vue";
import { useI18n } from "vue-i18n";

const { t } = useI18n();
const reference = ref("");

const emit = defineEmits<{
  (e: "cancel", value: boolean): void;
  (e: "receiveLines", reference: string): void;
}>();
</script>
