import { PurchaseOrderFilters } from "./PurchaseOrderFilters";

export class PurchaseOrderSearchRequest {
  query = "";
  page = 0;
  pageSize = 0;
  filters = new PurchaseOrderFilters();
  sortBy = "";
  sortOrder = "asc";

  constructor(query: string) {
    this.query = query;
  }

  public equals = (other: PurchaseOrderSearchRequest): boolean => {
    return (
      this.query === other.query &&
      this.page === other.page &&
      this.pageSize === other.pageSize &&
      this.sortBy === other.sortBy &&
      this.sortOrder === other.sortOrder &&
      this.filters.equals(other.filters)
    );
  };
}
