import { ReceivedQuantity } from "./ReceivedQuantity";

export class CalculateGoodsReceptionSummaryRequest {
  receivedQuantities: ReceivedQuantity[] = [];
  shippingPrice = 0;

  constructor(receivedQuantities: ReceivedQuantity[], shippingPrice: number) {
    this.receivedQuantities = receivedQuantities;
    this.shippingPrice = shippingPrice;
  }
}
