export class Supplier {
  id = "";
  name = "";
  supplierNumber = "";
  phoneNumber = "";
  contact = {
    firstName: "",
    lastName: "",
    email: "",
  };
  address = {
    addressLines: [],
    city: "",
    country: "",
    postalCode: "",
    countryIso: "",
  };
  incoterms = {
    code: "",
    description: "",
  };
}
