import { useAuth } from "@cumulus/event-bus";
import { Warehouse } from "./model/Warehouse";
import { warehouseApi } from "./WarehouseApi";
import { useErrorHandler } from "@/api/ErrorHandler";

export function useWarehouse() {
  const { handleError } = useErrorHandler();
  const { getAuthHeaders } = useAuth();

  const getAllWarehouses = async (): Promise<Warehouse[]> => {
    try {
      const authHeaders = await getAuthHeaders();
      return await warehouseApi.getAll(authHeaders);
    } catch (error) {
      await handleError(error);
      throw error;
    }
  };

  const getWarehouse = async (id: string): Promise<Warehouse> => {
    try {
      const authHeaders = await getAuthHeaders();
      return await warehouseApi.get(authHeaders, id);
    } catch (error) {
      await handleError(error);
      throw error;
    }
  };

  return { getWarehouse, getAllWarehouses };
}
