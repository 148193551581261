<template>
  <FloatLabelInput
    id="warehouse"
    dataTestId="warehouse"
    v-model:value="selectedWarehouse"
    :selectAllOnFocus="true"
    :label="t(`purchase.warehouse`)"
    :placeholder="t('placeholder.select', { property: t('purchase.warehouse').toLowerCase() })"
    :disabled="true"
    class="p-disabled"
  />
</template>

<script setup lang="ts">
import { computed, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import { Warehouse } from "@/repository/warehouse/model/Warehouse";
import { useWarehouse } from "@/repository/warehouse/WarehouseService";
import { PurchaseOrderLine } from "@/repository/purchase-order/model/PurchaseOrderLine";

const { t } = useI18n();
const warehouses = ref<Warehouse[]>([]);
const { getAllWarehouses } = useWarehouse();

const props = defineProps<{
  warehouseId: string;
  purchaseOrderLines: PurchaseOrderLine[];
}>();

const emit = defineEmits<{
  (e: "update:warehouseId", value: string): void;
  (e: "updatePurchaseOrderDelivery", value: Warehouse): void;
}>();

const selectedWarehouse = computed<string>({
  get: () => {
    return warehouses.value.find((wh) => wh.id === props.warehouseId)?.name ?? "";
  },
  set: (id: string) => {
    if (id === "") {
      return;
    }

    const warehouse = warehouses.value.find((wh) => wh.id === id);
    if (warehouse) {
      emit("updatePurchaseOrderDelivery", warehouse);
      emit("update:warehouseId", warehouse.id);
    }
  },
});

const fetchWarehouses = async () => {
  warehouses.value = await getAllWarehouses();
};
onMounted(fetchWarehouses);
</script>
