<template>
  <div class="flex items-center gap-2 mb-4">
    <div class="flex-1 text-color font-semibold leading-6" />
    <div>
      <Button
        data-testid="btn-selected-icon"
        @click="(event: Event) => emit('openSortList', event)"
        severity="secondary"
        variant="text"
        size="small"
        :class="{ 'c-toolbar-button-active': isSortingVisible }"
        v-tooltip.bottom="{
          value: t('common.toolbar.sorting'),
          showDelay: 1000,
          hideDelay: 100,
        }"
      >
        <i :class="selectedIcon"></i>
      </Button>
    </div>
    <div class="ml-2">
      <Button
        data-testid="c-refresh-button"
        @click="emit('refreshList')"
        severity="secondary"
        variant="text"
        size="small"
        v-tooltip.bottom="{
          value: t('common.toolbar.refresh'),
          showDelay: 1000,
          hideDelay: 300,
        }"
      >
        <i class="pi pi-refresh"></i>
      </Button>
    </div>
    <div class="ml-2">
      <Button
        data-testid="btn-column-chooser"
        @click="(event: Event) => emit('openColumnChooser', event)"
        severity="secondary"
        variant="text"
        size="small"
        :class="{ 'c-toolbar-button-active': isChooserVisible }"
        v-tooltip.bottom="{
          value: t('common.toolbar.column-chooser'),
          showDelay: 1000,
          hideDelay: 300,
        }"
      >
        <i class="pi pi-list"></i>
      </Button>
    </div>
    <div class="ml-2">
      <Button
        data-testid="c-show-sidebar"
        @click="emit('openFilterSidebar')"
        severity="secondary"
        variant="text"
        size="small"
        :class="{ 'c-toolbar-button-active': isFilterVisible }"
        v-tooltip.bottom="{
          value: t('common.toolbar.filter'),
          showDelay: 1000,
          hideDelay: 300,
        }"
      >
        <i class="pi pi-filter"></i>
      </Button>
    </div>
  </div>
</template>
<script setup lang="ts">
import { useI18n } from "vue-i18n";
const { t } = useI18n();

const isSortingVisible = defineModel<boolean>("isSortingVisible");
const isChooserVisible = defineModel<boolean>("isChooserVisible");
const selectedIcon = defineModel<string>("selectedIcon");
const isFilterVisible = defineModel<boolean>("isFilterVisible");

const emit = defineEmits<{
  (e: "refreshList"): void;
  (e: "openSortList", event: Event): void;
  (e: "openColumnChooser", event: Event): void;
  (e: "openFilterSidebar"): void;
}>();
</script>
