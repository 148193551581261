export const PurchaseOrderLinesColumns = {
  EstimatedArrivalDate: "estimatedArrivalDate",
  PurchaseOrderNumber: "purchaseOrderNumber",
  Status: "purchaseOrderStatus",
  SupplierNumber: "supplier.supplierNumber",
  SupplierName: "supplier.name",
  PurchaseOrderReference: "purchaseOrderReference",
  OpenLines: "openLines",
  OpenValue: "openValue",
  Warehouse: "warehouseId",
} as const;
