<template>
  <div class="mt-1">
    <FloatLabelInput
      id="shipping-price"
      :label="t('purchase.shipping-price')"
      v-model:value="shippingPriceComputed"
      :selectAllOnFocus="true"
      :placeholder="t('placeholder.type', { property: t('purchase.shipping-price').toLowerCase() })"
      dataTestId="shipping-price"
      :disabled="true"
      class="p-disabled"
    />
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { useI18n } from "vue-i18n";
const { t } = useI18n();

const props = defineProps<{
  shippingPrice: number | null;
}>();

const emit = defineEmits<{
  (e: "update:shippingPrice", value: number): void;
}>();

const shippingPriceComputed = computed<number>({
  get: () => {
    return props.shippingPrice ?? 0;
  },
  set: (value) => {
    emit("update:shippingPrice", value);
  },
});
</script>
