<template>
  <ToolbarSearchList
    :selectedIcon="selectedIcon"
    :isFilterVisible="props.showSidebar"
    :isSortingVisible="isSortingVisible"
    :isChooserVisible="isChooserVisible"
    @refreshList="emit('purchaseOrderListRefresh')"
    @openSortList="openSortList"
    @openColumnChooser="openColumnChooser"
    @openFilterSidebar="emit('openFilterSidebar')"
  >
  </ToolbarSearchList>
  <div class="flex items-left gap-2">
    <SearchHeader v-model:filters="filtersComputed" :warehouses="warehouses" />
  </div>
  <DataTable
    :value="purchaseOrders"
    dataKey="id"
    data-testid="purchase-search-result"
    class="c-datatable"
    :paginator="true"
    :lazy="true"
    :loading="loading"
    :rows="pageSize"
    :totalRecords="totalHits"
    @row-dblclick="onRowDblClick"
    @row-select="onRowSelected"
    :currentPageReportTemplate="
      t('search.current-page-template', {
        first: '{first}',
        last: '{last}',
        totalRecords: '{totalRecords}',
      })
    "
    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
    :sortOrder="sortOrder"
    :sortField="sortField"
    removableSort
    @sort="onSort"
    @page="onPage"
    :rowsPerPageOptions="[50, 100]"
    ref="searchPurchaseOrder"
    v-model:selection="selectedPurchaseOrder"
    :stripedRows="true"
    selectionMode="single"
    tabindex="0"
    :rowClass="addGlobalSearchClass"
    scrollable
    scrollHeight="75vh"
    :resizableColumns="true"
    columnResizeMode="fit"
    @column-resize-end="onColumnResizeEnd"
    :reorderable-columns="true"
    @column-reorder="onColumnReorder"
    :key="renderKey as unknown as number"
  >
    <Column
      v-for="(col, index) of selectedColumnsComputed as unknown as DataTableColumn[]"
      :field="col.field"
      :header="t(col.header)"
      :key="col.field + '_' + index"
      :class="col.class"
      :sortable="col.sortable"
      :pt="{
        headerCell: {
          id: col.field,
        },
      }"
      :style="col.size ? `width: ${col.size}px; max-width: ${col.size}px;` : ''"
    >
      <template #body="{ data, field, index }">
        <template v-if="col.field === PurchaseOrderLinesColumns.EstimatedArrivalDate">
          <span :data-testid="`estimated-arrival-date-${index}`">{{ d(data[field as keyof typeof data]) }}</span>
        </template>
        <template v-else-if="col.field === PurchaseOrderLinesColumns.PurchaseOrderNumber">
          <span
            :data-testid="`purchase-order-number-${index}`"
            class="c-purchase-order-number-text"
            @click="onPurchaseNumberClicked(data)"
            >{{ data[field as keyof typeof data] }}</span
          >
        </template>
        <template v-else-if="col.field === PurchaseOrderLinesColumns.Status">
          <span :data-testid="`purchase-order-status-${index}`">{{ getStatusTranslation(data) }}</span>
        </template>
        <template v-else-if="col.field === PurchaseOrderLinesColumns.SupplierNumber">
          <span :data-testid="`supplier-number-${index}`">{{ data.supplier.supplierNumber }}</span>
        </template>
        <template v-else-if="col.field === PurchaseOrderLinesColumns.SupplierName">
          <span :data-testid="`supplier-name-${index}`">{{ data.supplier.name }}</span>
        </template>
        <template v-else-if="col.field === PurchaseOrderLinesColumns.PurchaseOrderReference">
          <span :data-testid="`purchase-order-reference-${index}`">{{ data[field as keyof typeof data] }}</span>
        </template>
        <template v-else-if="col.field === PurchaseOrderLinesColumns.OpenLines">
          <OpenLines :purchaseOrderLines="data.purchaseOrderLines" :index="index" />
        </template>
        <template v-else-if="col.field === PurchaseOrderLinesColumns.OpenValue">
          <span :data-testid="`open-value-${index}`">{{ data[field as keyof typeof data] }}</span>
        </template>
        <template v-else-if="col.field === PurchaseOrderLinesColumns.Warehouse">
          <span :data-testid="`warehouse-name-${index}`">{{ getWarehouseName(data[field as keyof typeof data]) }}</span>
        </template>
        <template v-else>
          {{ data[field as keyof typeof data] }}
        </template>
      </template>
    </Column>

    <template #empty>{{ t("no-purchase-orders-found") }} </template>
  </DataTable>
  <PopOverSort
    ref="op"
    :searchColumns="goodReceptionSearchColumns"
    :selectedIcon="selectedIcon"
    :isSelectedColumn="isSelectedColumn"
    @onSelectedRow="onSelectedRow"
  />

  <PopOverColumnChooser
    ref="chooserRef"
    v-model:selectedColumns="selectedColumnsComputed"
    :columns="filteredColumns"
    :label="t('common.reset')"
    @resetColumns="resetColumns"
  />
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";

import { PurchaseOrder } from "@/repository/purchase-order/model/PurchaseOrder";
import { PurchaseOrderStatus } from "@/model/search/purchase-order-search/PurchaseOrderStatus";
import OpenLines from "./OpenLines.vue";
import { PurchaseOrderLinesColumns } from "@/model/search/purchase-order-search/PurchaseOrderLinesColumns";
import { computed, nextTick, ref, watch } from "vue";
import {
  type DataTablePageEvent,
  type DataTableRowDoubleClickEvent,
  type DataTableRowSelectEvent,
  type DataTableSortEvent,
} from "primevue/datatable";
import { useTablePreferences } from "@cumulus/components";
import type { DataTableColumn } from "@cumulus/components";
import { Warehouse } from "@/repository/warehouse/model/Warehouse";

import ToolbarSearchList from "./ToolbarSearchList.vue";
import SearchHeader from "./SearchHeader.vue";
import PopOverColumnChooser from "./PopOverColumnChooser.vue";
import { PurchaseOrderFilters } from "@/model/search/purchase-order-search/PurchaseOrderFilters";
import PopOverSort from "./PopOverSort.vue";

const { t, d } = useI18n();
const route = useRoute();

const searchPurchaseOrder = ref();
const selectedPurchaseOrder = ref<PurchaseOrder>(new PurchaseOrder());

const props = defineProps<{
  purchaseOrders: PurchaseOrder[];
  warehouses: Warehouse[];
  loading: boolean;
  totalHits: number;
  pageSize: number;
  page: number;
  sortOrder: number;
  sortField: string;
  employeeId: string;
  showSidebar: boolean;
  filters: PurchaseOrderFilters;
}>();

const emit = defineEmits<{
  (e: "onRowDblClicked", value: PurchaseOrder): void;
  (e: "update:page", value: number): void;
  (e: "update:pageSize", value: number): void;
  (e: "update:sortOrder", value: number): void;
  (e: "update:sortField", value: string): void;
  (e: "purchaseOrderListRefresh"): void;
  (e: "openFilterSidebar"): void;
  (e: "update:filters", value: PurchaseOrderFilters): void;
}>();

const goodReceptionSearchColumns = [
  { field: "estimatedArrivalDate", header: "expected-date", sortable: true },
  {
    field: "purchaseOrderNumber",
    header: "purchase-order-number",
    sortable: true,
  },
  {
    field: "purchaseOrderStatus",
    header: "status.label",
    sortable: true,
  },
  {
    field: "supplier.supplierNumber",
    header: "supplier-number",
    sortable: true,
  },
  {
    field: "supplier.name",
    header: "supplier-name",
    sortable: true,
  },
  { field: "purchaseOrderReference", header: "po-reference", sortable: true },
  {
    field: "openLines",
    header: "open-lines",
    class: "text-right",
    sortable: true,
  },
  {
    field: "openValue",
    header: "open-values",
    class: "text-right",
    sortable: true,
  },
  { field: "warehouseId", header: "warehouse", sortable: true },
];

const { selectedColumnsComputed, renderKey, onColumnReorder, resetColumns, orderedColumns, onColumnResizeEnd } =
  useTablePreferences("goodReceptionSearch", goodReceptionSearchColumns, null);

const sortBy = [
  { name: "Ascending", icon: "pi pi-sort-amount-up", value: 1 },
  { name: "Descending", icon: "pi pi-sort-amount-down", value: -1 },
];

const currentIconIndex = ref(0);
const defaultSortField = ref(
  goodReceptionSearchColumns.find((column) => column.field === PurchaseOrderLinesColumns.PurchaseOrderNumber),
);
const defaultSortOrder = ref(sortBy[currentIconIndex.value].value);

const isSelectedColumn = (column: DataTableColumn) => {
  return column.field === (defaultSortField.value?.field ?? "");
};

const onSelectedRow = (row: DataTableColumn) => {
  nextTick(() => {
    focusSearchResult(selectedPurchaseOrder.value.id);
    defaultSortOrder.value = defaultSortOrder.value === -1 ? 1 : -1;
    currentIconIndex.value = defaultSortOrder.value === 1 ? 1 : 0;
    defaultSortField.value = row;
    emit("update:sortOrder", -defaultSortOrder.value);

    if (defaultSortField.value) {
      emit("update:sortField", defaultSortField.value.field);
    }
    emit("update:page", 1);
  });
};

const filteredColumns = computed(() => {
  return orderedColumns.value;
});

const isSortingVisible = computed<boolean>(() => {
  return op.value?.visible ?? false;
});

const isChooserVisible = computed<boolean>(() => {
  return chooserRef.value?.visible ?? false;
});

const selectedIcon = computed(() => {
  return sortBy[currentIconIndex.value].icon;
});

const op = ref();

const openSortList = (event: Event) => {
  op.value.toggle(event);
};

const chooserRef = ref();

const openColumnChooser = (event: Event) => {
  chooserRef.value.toggle(event);
};

const filtersComputed = computed({
  get: () => {
    return props.filters;
  },
  set: (value: PurchaseOrderFilters) => {
    emit("update:filters", value);
  },
});

const addGlobalSearchClass = (data: PurchaseOrder | null | undefined) => {
  if (!data || typeof data.id === "undefined") {
    return ["c-search-result-row"];
  }

  return [
    `c-search-result-row c-sr-id-${data.id}`,
    {
      "c-search-result-row-selected": selectedPurchaseOrder.value?.id === data.id,
    },
  ];
};

watch([() => props.purchaseOrders, () => props.loading], ([purchaseOrders, loading]) => {
  if (loading === false && purchaseOrders.length > 0) {
    nextTick(() => {
      if (route.query?.focusResult) {
        focusSearchResult((route.query?.focusResult as string) ?? "");
      }
    });
  }
});

const focusSearchResult = (id = "") => {
  if (props.purchaseOrders.length > 0 && searchPurchaseOrder.value) {
    const element = id === "" ? null : searchPurchaseOrder.value.$el.querySelector(`tr.c-sr-id-${id}`);
    if (element) {
      element.focus();
      return;
    }

    searchPurchaseOrder.value.$el.querySelector("tbody tr:first-of-type").focus();
  }
};

const onPage = async (event: DataTablePageEvent) => {
  nextTick(() => {
    focusSearchResult();
    emit("update:page", event.page + 1);
    emit("update:pageSize", event.rows);
  });
};

const getStatusTranslation = (purchaseOrder: PurchaseOrder) => {
  switch (purchaseOrder.purchaseOrderStatus) {
    case PurchaseOrderStatus.Open:
      return t("status.open");
    case PurchaseOrderStatus.PartiallyReceived:
      return t("status.partiallyreceived");
    case PurchaseOrderStatus.Received:
      return t("status.received");
    default:
      return "";
  }
};

const onSort = async (event: DataTableSortEvent) => {
  let sortField = "";
  nextTick(() => {
    if (typeof event.sortField === "string") {
      sortField = event.sortField;
    }
    focusSearchResult();

    emit("update:sortOrder", -props.sortOrder);
    emit("update:sortField", sortField);
    emit("update:page", 1);
  });
};

const onPurchaseNumberClicked = (purchaseOrder: PurchaseOrder) => {
  selectedPurchaseOrder.value = purchaseOrder;
  emit("onRowDblClicked", purchaseOrder);
};

const onRowDblClick = (event: DataTableRowDoubleClickEvent) => {
  emit("onRowDblClicked", event.data);
};

const onRowSelected = (event: DataTableRowSelectEvent) => {
  if (!(event.originalEvent instanceof KeyboardEvent)) {
    return;
  }
  if (event.originalEvent.key !== "Enter") {
    return;
  }
  emit("onRowDblClicked", event.data);
};

const getWarehouseName = (warehouseId: string) => {
  return props.warehouses.find((wh) => wh.id === warehouseId)?.name ?? "";
};
</script>
<style scoped lang="scss">
.c-purchase-order-number-text:hover {
  text-decoration: underline;
}

:deep(.p-paginator) {
  .p-paginator-first {
    margin-left: auto;
  }
  .p-paginator-current {
    margin-left: auto;
  }
}
</style>
