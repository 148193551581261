<template>
  <label :for="inputId"> {{ label }} </label>
  <AutoComplete
    v-model="valuesComputed"
    class="c-ul-width w-full"
    :data-testid="dataTestId"
    :placeholder="placeholder"
    :inputId="inputId"
    multiple
    fluid
    :typeahead="false"
  />
</template>

<script setup lang="ts">
import { computed } from "vue";

const props = defineProps<{
  label?: string;
  values?: string[];
  dataTestId?: string;
  placeholder?: string;
  inputId?: string;
}>();

const emit = defineEmits<{
  (event: "update:values", value: string[]): void;
}>();

const valuesComputed = computed<string[]>({
  get: () => {
    return props.values && props.values.length > 0 ? props.values : [];
  },
  set: (value: string[]) => {
    emit("update:values", value);
  },
});
</script>

<style scoped lang="scss">
.c-ul-width :deep(ul) {
  width: 100%;
}

label {
  font-family: var(--font-family);
}
</style>
