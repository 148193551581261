<template>
  <FloatLabelInput
    id="payment-term"
    dataTestId="default-payment-term"
    v-model:value="paymentTermComputed"
    :label="t(`purchase.payment-term`)"
    :placeholder="t('placeholder.select', { property: t('purchase.payment-term').toLowerCase() })"
    :selectAllOnFocus="true"
    :disabled="true"
    class="p-disabled"
  />
</template>

<script setup lang="ts">
import { computed } from "vue";
import { useI18n } from "vue-i18n";

const { t } = useI18n();

const props = defineProps<{
  paymentTerm: string;
}>();

const emit = defineEmits<{
  (e: "update:paymentTerm", value: string): void;
}>();

const paymentTermComputed = computed<string>({
  get: () => {
    return props.paymentTerm ?? "";
  },
  set: (value) => {
    emit("update:paymentTerm", value);
  },
});
</script>
