<template>
  <div v-if="Array.isArray(filterValue)">
    <li
      v-for="(value, index) in filterValue"
      :key="index"
      class="mr-3 px-2 py-1 text-[#4a728f] rounded-2xl bg-[#e7f0f7] inline-flex items-center"
    >
      <span :data-testid="dataTestId + '-label-' + index">{{ value }}</span>
      <span
        class="ml-2 cursor-pointer pi pi-times-circle"
        :data-testid="dataTestId + '-delete-btn-' + index"
        @click="emit('removeFilter', filterType, index)"
      >
      </span>
    </li>
  </div>
  <li v-else-if="filterValue" class="mr-3 px-2 text-[#4a728f] rounded-2xl bg-[#e7f0f7] inline-flex items-center">
    <span :data-testid="dataTestId + '-label-' + 0">{{ filterValue }}</span>
    <span
      class="ml-2 cursor-pointer pi pi-times-circle"
      @click="emit('removeFilter', filterType, 0)"
      :data-testid="dataTestId + '-delete-btn-' + 0"
    >
    </span>
  </li>
</template>

<script setup lang="ts">
import { PurchaseOrderFilters } from "@/model/search/purchase-order-search/PurchaseOrderFilters";

defineProps<{
  filterValue: string[] | string;
  filterType: keyof PurchaseOrderFilters | string;
  dataTestId: string;
}>();

const emit = defineEmits<{
  (event: "removeFilter", filterType: keyof PurchaseOrderFilters | string, index: number): void;
}>();
</script>
