import { Component } from "vue";
import { RouteRecordRaw } from "vue-router";

export const goodsReceptionRoutes = [
  {
    path: "/goods-reception/search/:search?",
    name: "goods-reception-search",
    component: (): Component => import("@/views/PurchaseOrderSearch.vue"),
    meta: {
      authRequired: true,
      title: "title.search-goods-reception",
    },
  },
  {
    path: "/goods-reception/receive/:id",
    name: "receive-goods",
    component: (): Component => import("@/views/GoodsReception.vue"),
    meta: {
      authRequired: true,
      title: "title.receive-goods",
    },
  },
];

export const mainRoutes: RouteRecordRaw[] =
  import.meta.env.VITE_APP_STANDALONE === "true" && !import.meta.env.VITE_APP_TESTS
    ? [
        {
          name: "Pages",
          path: "/",
          component: (): Component => import("./Routes.vue"),
        },
        {
          name: "oauth2-redirect",
          path: "/oauth2-redirect",
          component: (): Component => import("@/components/OAuth2Redirect.vue"),
        },
        {
          name: "oauth2-singout",
          path: "/oauth2-singout",
          component: (): Component => import("@/components/OAuth2Signout.vue"),
        },
        {
          name: "Failed",
          path: "/failed",
          component: (): Component => import("@/components/Failed.vue"),
        },
      ]
    : [];
