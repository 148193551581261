<template>
  <Popover
    ref="commentPanelRef"
    appendTo="body"
    :dismissable="true"
    id="comment_overlay_panel"
    class="c-comment-panel"
    @keydown.esc.stop="hideOverlayPanel"
    @show="focusTextarea"
  >
    <PrimeTextarea
      ref="textareaRef"
      class="c-textarea"
      data-testid="comment-textarea"
      v-model="comment"
      rows="6"
      cols="30"
      maxlength="15000"
      @keydown.tab="tabKeyPressed"
      disabled="true"
    />
  </Popover>
</template>

<script setup lang="ts">
import { ref } from "vue";

const commentPanelRef = ref();
const comment = defineModel<string>("comment");

const toggle = (event: Event) => commentPanelRef.value.toggle(event);
const show = (event: Event) => commentPanelRef.value.show(event);
const hideOverlayPanel = () => commentPanelRef.value.hide();

defineExpose({ toggle: toggle, show: show, hideOverlayPanel: hideOverlayPanel });

const emit = defineEmits<{
  (event: "tabKeyPressed"): void;
}>();

const textareaRef = ref();
const focusTextarea = () => textareaRef.value.$el?.focus();
const tabKeyPressed = () => {
  hideOverlayPanel();
  emit("tabKeyPressed");
};
</script>

<style scoped lang="scss">
.c-comment-panel .p-popover-content {
  height: auto;
}

.c-textarea {
  border-color: var(--active-component-color);
  &:focus-visible {
    outline-color: var(--active-component-color);
  }
}
</style>
