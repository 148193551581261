<template>
  <div class="c-toolbar-wrapper">
    <div class="c-toolbar">
      <div class="c-header-icon-container">
        <div v-tooltip.bottom="saveButtonTooltip">
          <PrimeButton
            data-testid="header-save-button"
            id="header-save-button"
            class="c-circular-button mr-4"
            @click="emit('saveClicked')"
            :disabled="!hasUpdates"
          >
            <i class="pi pi-check c-success-button c-circular-icon"></i>
            <span class="px-4">{{ t("common.save") }}</span>
          </PrimeButton>
        </div>
        <PrimeButton
          data-testid="header-cancel-button"
          id="header-cancel-button"
          class="c-circular-button"
          @click="emit('onCancel')"
        >
          <i class="pi pi-times c-warning-button c-circular-icon"></i>
          <span class="px-4">{{ t("common.cancel") }}</span>
        </PrimeButton>
        <div v-tooltip.bottom="receivedButtonTooltip">
          <PrimeButton
            data-testid="header-receive-button"
            class="c-circular-button ml-4"
            @click="emit('onReceiveItems')"
            :disabled="purchaseOrderStatus === PurchaseOrderStatus.Received"
          >
            <i class="pi pi-check c-default-button c-circular-icon"></i>
            <span class="px-4">{{ receiveButton }}</span>
          </PrimeButton>
        </div>
      </div>
    </div>
  </div>
  <UnsavedChangesDialog
    position="top"
    :visible="unsavedChangesDialogVisible"
    :dialogHeader="t('common.unsaved-changes-header')"
    @cancelClicked="emit('dialogCancelBtnClicked')"
    @discardClicked="emit('dialogDiscardBtnClicked')"
    @saveClicked="emit('saveClicked')"
    ><template #content>{{ t("common.unsaved-changes-text") }}</template>
    <template #cancelBtn>{{ t("common.cancel") }}</template>
    <template #discardBtn>{{ t("common.discard") }}</template>
    <template #saveBtn>{{ t("common.save") }}</template>
  </UnsavedChangesDialog>
</template>
<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { UnsavedChangesDialog } from "@cumulus/components";
import { computed } from "vue";
import { PurchaseOrderStatus } from "@/model/search/purchase-order-search/PurchaseOrderStatus";
import { PurchaseOrderLineToReceive } from "@/repository/goods-reception/model/PurchaseOrderLineToReceive";

const { t } = useI18n();
const props = defineProps<{
  hasUpdates: boolean;
  editMode: boolean;
  unsavedChangesDialogVisible: boolean;
  purchaseOrderStatus: PurchaseOrderStatus;
  selectedLines: PurchaseOrderLineToReceive[];
  initialPurchaseOrderLinesToReceive: PurchaseOrderLineToReceive[];
}>();

const emit = defineEmits<{
  (e: "saveClicked"): void;
  (e: "onReceiveItems"): void;
  (e: "onCancel"): void;
  (e: "dialogDiscardBtnClicked"): void;
  (e: "dialogCancelBtnClicked"): void;
}>();
const saveButtonTooltip = computed(() => {
  return props.hasUpdates ? { value: "" } : { value: t("common.save-tooltip"), showDelay: 100, hideDelay: 300 };
});

const receivedButtonTooltip = computed(() => {
  return props.purchaseOrderStatus === PurchaseOrderStatus.Received
    ? { value: t("common.received-tooltip"), showDelay: 100, hideDelay: 300 }
    : { value: "", showDelay: 100, hideDelay: 300 };
});

const receiveButton = computed(() => {
  return props.selectedLines.length === 0 ||
    props.selectedLines.length === props.initialPurchaseOrderLinesToReceive.filter((row) => row.openQuantity > 0).length
    ? t("common.receive-all")
    : t("common.receive-selected");
});
</script>

<style scoped lang="scss">
.c-circular-button:disabled {
  opacity: 0.6;
}
</style>
