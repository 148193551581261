import { AuthHeaders, httpClient } from "@cumulus/http";
import { ProductSupplierPrice } from "@/model/product/ProductSupplierPrice";
import { Product } from "@/model/product/Product";

class ProductApi {
  private uri = "";

  constructor() {
    this.uri =
      import.meta.env.VITE_APP_API_PRODUCT != undefined && import.meta.env.VITE_APP_API_PRODUCT
        ? import.meta.env.VITE_APP_API_PRODUCT
        : `${import.meta.env.VITE_APP_API_URL as string}`;
  }

  public async getProductSupplierPrices(authHeaders: AuthHeaders, productId: string): Promise<ProductSupplierPrice[]> {
    return await httpClient(this.uri, authHeaders)
      .get(`/products/${productId}/supplier-prices`)
      .then(({ data }) => data);
  }

  public async getProductById(authHeaders: AuthHeaders, productId: string): Promise<Product> {
    return await httpClient(this.uri, authHeaders)
      .get(`/products/${productId}`)
      .then(({ data }) => data);
  }
}

export const productApi = new ProductApi();
