<template>
  <SearchHeader
    :showSidebar="showSidebar"
    v-model:filters="filtersComputed"
    :warehouses="warehouses"
    @onToggleFilterSidebar="toggleFilterSidebar"
  />
  <div class="c-goods-reception-search-result">
    <div class="flex">
      <div class="flex-auto">
        <Card>
          <template #content>
            <div class="c-card">
              <Suspense>
                <PurchaseOrderList
                  :purchaseOrders="purchaseOrders"
                  :warehouses="warehouses"
                  :loading="loading"
                  :totalHits="totalHits"
                  :pageSize="pageSize"
                  :page="page"
                  :sortField="sortField"
                  :sortOrder="sortOrder"
                  :employeeId="employeeId"
                  @onRowDblClicked="onClicked"
                  @update:page="onUpdatePage"
                  @update:sortOrder="onUpdateSortOrder"
                  @update:sortField="onUpdateSortField"
                  @update:pageSize="onUpdatePageSize"
                  @purchaseOrderListRefresh="search"
                />
              </Suspense>
            </div>
          </template>
        </Card>
      </div>
      <div class="pb-2" :class="showSidebar ? 'pl-5' : ''">
        <FilterSidebar :showSidebar="showSidebar" v-model:filters="filtersComputed" :warehouses="warehouses" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";

import FilterSidebar from "@/components/purchase-order-search/FilterSidebar.vue";
import PurchaseOrderList from "@/components/purchase-order-search/PurchaseOrderList.vue";
import SearchHeader from "@/components/purchase-order-search/SearchHeader.vue";
import { PurchaseOrderFilters } from "@/model/search/purchase-order-search/PurchaseOrderFilters";
import { useSearch } from "@/api/search/SearchService";
import { Warehouse } from "@/repository/warehouse/model/Warehouse";
import { useWarehouse } from "@/repository/warehouse/WarehouseService";
import { NIL as emptyUuid } from "uuid";
import { useToast } from "primevue/usetoast";
import { useCumulusToast } from "@cumulus/toast";
import { useI18n } from "vue-i18n";

import { PurchaseOrderSearchRequest } from "@/model/search/purchase-order-search/PurchaseOrderSearchRequest";
import { PurchaseOrder } from "@/repository/purchase-order/model/PurchaseOrder";

import { useAuth } from "@cumulus/event-bus";

const { getUser } = useAuth();
/*
Purchase Order Search exist in Goods Reception MF and in Purchase MF
 */

const showSidebar = ref(true);
const router = useRouter();
const route = useRoute();
const { searchForPurchaseOrders } = useSearch();
const { getAllWarehouses } = useWarehouse();

const warehouses = ref<Warehouse[]>([]);
const purchaseOrders = ref<PurchaseOrder[]>([]);
const query = ref("");
const pageSize = ref<number>(50);
const page = ref(1);
const sortField = ref("");
const sortOrder = ref(-1);
const totalHits = ref(0);
const loading = ref(false);
const filters = ref(new PurchaseOrderFilters());
const toast = useCumulusToast(useToast());
const { t } = useI18n();

watch(
  () => route.query.search,
  async (newQuery) => {
    if (newQuery !== undefined) {
      query.value = newQuery as string;
      loading.value = true;
      await search();
    }
  }
);

const filtersComputed = computed<PurchaseOrderFilters>({
  get: () => filters.value,
  set: async (value: PurchaseOrderFilters) => {
    filters.value = value;
    await search();
  },
});

const onUpdatePageSize = (value: number) => {
  pageSize.value = value;
  loading.value = true;
  search();
};

const employeeId = ref<string>("");
onMounted(async () => {
  employeeId.value = (await getUser()).getEmployee().id;
  warehouses.value = await getAllWarehouses();

  query.value = route.params.query as string;
  await search();
});

const onUpdateSortOrder = (value: number) => {
  sortOrder.value = value;
  loading.value = true;
};

const onUpdateSortField = (value: string) => {
  sortField.value = value;
  loading.value = true;
};

const search = async () => {
  try {
    loading.value = true;
    const request = new PurchaseOrderSearchRequest(query.value);

    request.page = page.value;
    request.pageSize = pageSize.value;
    if (sortField.value === "") {
      sortOrder.value = -1;
    }
    request.sortBy = sortField.value;
    request.sortOrder = sortOrder.value === 1 ? "asc" : "desc";
    request.filters = filters.value;
    const response = await searchForPurchaseOrders(request);
    purchaseOrders.value = response.purchaseOrders;
    totalHits.value = response.totalHits;
  } finally {
    loading.value = false;
  }
};

const onUpdatePage = (value: number) => {
  page.value = value;
  loading.value = true;
  search();
};

watch(
  () => route.query.search,
  async (newQuery) => {
    if (newQuery !== undefined) {
      query.value = newQuery as string;
      page.value = 1;
      await search();
    }
  }
);

const toggleFilterSidebar = () => {
  showSidebar.value = !showSidebar.value;
};

const onClicked = (purchaseOrder: PurchaseOrder) => {
  purchaseOrder.id != null && purchaseOrder.id !== emptyUuid
    ? router.push({ name: "receive-goods", params: { id: purchaseOrder.id } })
    : toast.add({
        severity: "warn",
        summary: t("product.toast.open-product-failed"),
        detail: t("product.search-header.no-product-selected"),
        closable: true,
      });
};
</script>

<style scoped lang="scss">
.c-goods-reception-search-result {
  margin: var(--default-content-margin);
  @media (min-width: 992px) {
    margin-bottom: 2rem;
  }
}
</style>
