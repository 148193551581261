<template>
  <div data-testid="summary" class="flex justify-end p-2">
    <div class="flex-initial w-fit">
      <Card>
        <template #content>
          <div class="flex flex-wrap mr-4">
            <div class="mr-8">
              <div class="flex h-full">
                <div class="flex items-center self-end">
                  {{ t("freight") }}:
                  <InputNumber
                    :modelValue="freight"
                    :min-fraction-digits="2"
                    :max-fraction-digits="2"
                    placeholder="0"
                    :allow-empty="false"
                    inputClass="w-24 text-center ml-4"
                    data-testid="freight-input"
                    mode="currency"
                    currencyDisplay="narrowSymbol"
                    :currency="currencyIso"
                    @update:modelValue="onFreightChanged"
                    @keydown.tab="tabKeydownInSearch"
                  />
                </div>
              </div>
            </div>

            <div class="c-col-2 pl-4">
              <div class="flex">
                <p>{{ t("sum-in-reception") }}:</p>
                <p data-testid="sum-in-reception" class="ml-2">
                  {{ n(summary.receivedQuantitySum, "currency", props.currencyIso) }}
                </p>
              </div>
              <div class="flex flex-col">
                <div data-testid="currency">{{ t("total", { currency: props.currencyIso }) }}:</div>
                <div>
                  <span data-testid="sum-inc-freight" class="text-xl mr-2">{{
                    n(summary?.receivedQuantitySumIncFreight, "currency", props.currencyIso)
                  }}</span>
                  <span>{{ t("inclFreight") }}</span>
                </div>
              </div>
            </div>
          </div>
        </template>
      </Card>
    </div>
  </div>
</template>
<script setup lang="ts">
import { computed, nextTick, ref } from "vue";
import { useI18n } from "vue-i18n";
import { CalculatedGoodsReceptionSummaryResponse } from "../repository/goods-reception/model/CalculatedGoodsReceptionSummaryResponse";

const { t, n } = useI18n();
const freight = ref(0);

const onFreightChanged = (value: number) => {
  freight.value = value;
  emit("freight-changed", value);
};

const emit = defineEmits<{
  (e: "freight-changed", value: number): void;
}>();

const props = defineProps<{
  currencyIso: string;
  summary: CalculatedGoodsReceptionSummaryResponse;
}>();

const currencyIso = computed(() => (props.currencyIso ? props.currencyIso : "NOK"));

const tabKeydownInSearch = (event: Event) => {
  const elementBtnHeaderSave = document.getElementById("header-save-button") as HTMLButtonElement;
  const elementBtnHeaderCancel = document.getElementById("header-cancel-button");

  if (elementBtnHeaderSave?.disabled) {
    nextTick(() => elementBtnHeaderCancel?.focus());
  } else {
    nextTick(() => elementBtnHeaderSave?.focus());
  }
  event.preventDefault();
};
</script>
