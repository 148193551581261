<template>
  <div class="c-toolbar-wrapper">
    <div class="c-toolbar">
      <div class="c-header-icon-container">
        <div v-tooltip.bottom="saveButtonTooltip">
          <PrimeButton
            data-testid="header-save-button"
            id="header-save-button"
            class="c-circular-button mr-4"
            @click="emit('saveClicked')"
            :disabled="!hasUpdates"
          >
            <i class="pi pi-check c-success-button c-circular-icon"></i>
            <span class="px-4">{{ t("common.save") }}</span>
          </PrimeButton>
        </div>
        <PrimeButton
          data-testid="header-cancel-button"
          id="header-cancel-button"
          class="c-circular-button"
          @click="emit('onCancel')"
        >
          <i class="pi pi-times c-warning-button c-circular-icon"></i>
          <span class="px-4">{{ t("common.cancel") }}</span>
        </PrimeButton>
        <PrimeButton data-testid="header-receive-button" class="c-circular-button ml-4" @click="emit('onReceiveItems')">
          <i class="pi pi-sign-out c-default-button c-circular-icon"></i>
          <span class="px-4">{{ t("common.receive") }}</span>
        </PrimeButton>
      </div>
    </div>
  </div>
  <UnsavedChangesDialog
    position="top"
    :visible="unsavedChangesDialogVisible"
    :dialogHeader="t('common.unsaved-changes-header')"
    @cancelClicked="emit('dialogCancelBtnClicked')"
    @discardClicked="emit('dialogDiscardBtnClicked')"
    @saveClicked="emit('saveClicked')"
    ><template #content>{{ t("common.unsaved-changes-text") }}</template>
    <template #cancelBtn>{{ t("common.cancel") }}</template>
    <template #discardBtn>{{ t("common.discard") }}</template>
    <template #saveBtn>{{ t("common.save") }}</template>
  </UnsavedChangesDialog>
</template>
<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { UnsavedChangesDialog } from "@cumulus/components";
import { computed } from "vue";

const { t } = useI18n();
const props = defineProps<{
  hasUpdates: boolean;
  editMode: boolean;
  unsavedChangesDialogVisible: boolean;
}>();

const emit = defineEmits<{
  (e: "saveClicked"): void;
  (e: "onReceiveItems"): void;
  (e: "onCancel"): void;
  (e: "dialogDiscardBtnClicked"): void;
  (e: "dialogCancelBtnClicked"): void;
}>();
const saveButtonTooltip = computed(() => {
  return props.hasUpdates ? { value: "" } : { value: t("common.save-tooltip"), showDelay: 100, hideDelay: 300 };
});
</script>

<style scoped lang="scss">
.c-circular-button:disabled {
  opacity: 0.6;
}
</style>
