import { PurchaseOrderStatus } from "./PurchaseOrderStatus";

export class PurchaseOrderFilters {
  purchaseOrderNumber: string[] = [];
  supplierNumbers: string[] = [];
  productNumbers: string[] = [];
  supplierProductNumbers: string[] = [];
  warehouseIds: string[] = [];
  purchaseOrderStatuses: PurchaseOrderStatus[] = [];
  fromDate: string | null = null;
  toDate: string | null = null;

  public equals = (other: PurchaseOrderFilters): boolean => {
    return (
      this.purchaseOrderNumber === other.purchaseOrderNumber &&
      this.supplierNumbers === other.supplierNumbers &&
      this.productNumbers === other.productNumbers &&
      this.supplierProductNumbers === other.supplierProductNumbers &&
      this.warehouseIds === other.warehouseIds &&
      this.purchaseOrderStatuses === other.purchaseOrderStatuses &&
      this.fromDate === other.fromDate &&
      this.toDate === other.toDate
    );
  };
}
