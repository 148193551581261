import { PurchaseOrderProduct } from "./PurchaseOrderProduct";
export class PurchaseOrderLine {
  id = "";
  positionNumber = 0;
  product: PurchaseOrderProduct = new PurchaseOrderProduct();
  sumLine = 0;
  estimatedArrivalDate = new Date();
  quantity = 0;
  receivedQuantity = 0;
  purchasePrice = 0;
  confirmedArrivalDate = "";
  reference = "";
}
