import { productApi } from "./ProductApi";
import { useErrorHandler } from "@/api/ErrorHandler";
import { Product } from "@/model/product/Product";
import { ProductSupplierPrice } from "@/model/product/ProductSupplierPrice";
import { useAuth } from "@cumulus/event-bus";

export function useProduct() {
  const { handleError } = useErrorHandler();
  const { getAuthHeaders } = useAuth();

  const getProductSupplierPrices = async (productId: string): Promise<ProductSupplierPrice[]> => {
    try {
      const authHeaders = await getAuthHeaders();
      return await productApi.getProductSupplierPrices(authHeaders, productId);
    } catch (error) {
      await handleError(error);
      throw error;
    }
  };

  const getProductById = async (productId: string): Promise<Product> => {
    try {
      const authHeaders = await getAuthHeaders();
      return await productApi.getProductById(authHeaders, productId);
    } catch (error) {
      await handleError(error);
      throw error;
    }
  };
  return { getProductSupplierPrices, getProductById };
}
