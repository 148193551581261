import { CurrencyState } from "./CurrencyState";

export class Currency {
  id = "";
  buyRate = 0;
  unit = 0;
  baseCurrencyIso = "";
  currencyIso = "";
  currencyState: CurrencyState = CurrencyState.Active;
}
