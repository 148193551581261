import PrimeVue from "primevue/config";
import Aura from "@primevue/themes/aura";
import { definePreset } from "@primevue/themes";
import DefaultTheme from "../../Layout/src/assets/theme/default";

import { createApp } from "vue";
import { i18n } from "./locales/i18n";
import { createPinia } from "pinia";

import Button from "primevue/button";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Tooltip from "primevue/tooltip";
import MultiSelect from "primevue/multiselect";
import ConfirmationService from "primevue/confirmationservice";
import ConfirmPopup from "primevue/confirmpopup";
import ToastService from "primevue/toastservice";
import InputText from "primevue/inputtext";
import Select from "primevue/select";
import AutoCompletePrime from "primevue/autocomplete";
import Checkbox from "primevue/checkbox";
import InputNumber from "primevue/inputnumber";

import Tag from "primevue/tag";
import Toast from "primevue/toast";
import TextArea from "primevue/textarea";
import Dialog from "primevue/dialog";
import Menu from "primevue/menu";
import Popover from "primevue/popover";
import Card from "primevue/card";
import Tabs from "primevue/tabs";
import TabList from "primevue/tablist";
import Tab from "primevue/tab";
import TabPanels from "primevue/tabpanels";
import TabPanel from "primevue/tabpanel";

import FloatLabel from "primevue/floatlabel";

import { FloatLabelInput } from "@cumulus/components";
import { FloatLabelDropdownPanel } from "@cumulus/components";

import { FloatLabelInputNumber } from "@cumulus/components";
import vueDebounce from "vue-debounce";

const MyPreset = definePreset(Aura, DefaultTheme);

export default function configureApp(app: ReturnType<typeof createApp>) {
  const pinia = createPinia();
  app.use(PrimeVue, {
    theme: {
      preset: MyPreset,
      inputVariant: "outlined", //"filled"
      options: {
        //prefix: "m",
        darkModeSelector: ".dark",
        cssLayer: {
          name: "primevue",
          order: "tailwind-base, primevue, tailwind-utilities",
        },
      },
    },
  });
  app.use(ConfirmationService);
  app.use(ToastService);
  app.use(i18n);
  app.use(pinia);

  app.component("Tag", Tag);
  app.component("Toast", Toast);
  app.component("PrimeButton", Button);
  app.component("DataTable", DataTable);
  app.component("Column", Column);
  app.component("MultiSelect", MultiSelect);
  app.component("InputText", InputText);
  app.component("Select", Select);
  app.component("AutoCompletePrime", AutoCompletePrime);
  app.component("Checkbox", Checkbox);
  app.component("PrimeTextArea", TextArea);
  app.component("InputNumber", InputNumber);
  app.component("PrimeDialog", Dialog);
  app.component("ConfirmPopup", ConfirmPopup);
  app.component("PrimeMenu", Menu);
  app.component("Popover", Popover);
  app.component("PrimeTextarea", TextArea);
  app.component("Card", Card);
  app.component("TabList", TabList);
  app.component("Tab", Tab);
  app.component("Tabs", Tabs);
  app.component("TabPanels", TabPanels);
  app.component("TabPanel", TabPanel);

  app.component("FloatLabelInput", FloatLabelInput);
  app.component("FloatLabelDropdownPanel", FloatLabelDropdownPanel);
  app.component("FloatLabel", FloatLabel);

  app.component("FloatLabelInputNumber", FloatLabelInputNumber);
  app.directive("tooltip", Tooltip);
  app.directive("debounce", vueDebounce({ lock: true }));
}
