export class PurchaseOrderDelivery {
  name = "";
  address = {
    addressLines: [],
    city: "",
    country: "",
    postalCode: "",
    countryIso: "",
  };
}
