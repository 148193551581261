import { useAuth } from "@cumulus/event-bus";
import { searchApi } from "./SearchApi";
import { useErrorHandler } from "../ErrorHandler";
import { PurchaseOrderSearchRequest } from "@/model/search/purchase-order-search/PurchaseOrderSearchRequest";
import { PurchaseOrderSearchReponse } from "@/model/search/purchase-order-search/PurchaseOrderSearchResponse";

export function useSearch() {
  const { handleError } = useErrorHandler();
  const { getAuthHeaders } = useAuth();

  //Purchase Order Search (Good reception search) is the same as PurchaseOrder search in Purchase MF
  const searchForPurchaseOrders = async (request: PurchaseOrderSearchRequest): Promise<PurchaseOrderSearchReponse> => {
    try {
      const authHeaders = await getAuthHeaders();
      return await searchApi.searchForPurchaseOrders(authHeaders, request);
    } catch (error) {
      await handleError(error);
      return new PurchaseOrderSearchReponse();
    }
  };

  return {
    searchForPurchaseOrders,
  };
}
