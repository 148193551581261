<template>
  <label>{{ t("warehouse") }}</label>
  <MultiSelect
    id="select-warehouse"
    :data-testid="dataTestId"
    :options="warehouses"
    optionLabel="name"
    optionValue="id"
    v-model="selectedWarehouseComputed"
    class="w-full"
    :placeholder="t('placeholder.select', { property: t('warehouse').toLowerCase() })"
    display="chip"
    :filter="true"
    :pt:list:data-testid="`${dataTestId}-list`"
  />
</template>

<script setup lang="ts">
import { computed } from "vue";
import { useI18n } from "vue-i18n";

import { Warehouse } from "../repository/warehouse/model/Warehouse";

const { t } = useI18n();

const props = defineProps<{
  selectedWarehouseIds?: string[];
  warehouses?: Warehouse[];
  dataTestId?: string;
}>();

const emit = defineEmits<{
  (event: "update:selectedWarehouseIds", value: string[]): void;
}>();

const selectedWarehouseComputed = computed<string[]>({
  get: () => props.selectedWarehouseIds ?? [],
  set: (value) => emit("update:selectedWarehouseIds", value),
});
</script>

<style scoped>
label {
  font-family: var(--font-family);
}
</style>
