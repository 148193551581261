<template>
  <span :data-testid="`open-lines-${index}`">{{ openLinesComputed }}</span>
</template>
<script setup lang="ts">
import { PurchaseOrderLine } from "@/repository/purchase-order/model/PurchaseOrderLine";
import { computed } from "vue";

const props = defineProps<{
  purchaseOrderLines: PurchaseOrderLine[];
  index: number;
}>();

const openLinesComputed = computed(() => {
  return props.purchaseOrderLines.filter((x) => x.receivedQuantity < x.quantity).length;
});
</script>
