<template>
  <div class="c-toolbar-wrapper">
    <div class="c-toolbar">
      <div class="c-header-filter-container c-header-icon-container">
        <ActiveFilters v-model:filters="filtersComputed" :warehouses="warehouses" />
        <PrimeButton class="c-circular-button" @click="emit('onToggleFilterSidebar')" data-testid="c-show-sidebar">
          <i class="pi pi-filter c-default-button c-circular-icon"></i>
          <span class="px-4">{{ showSidebar ? t("search.hide-filter") : t("search.show-filter") }}</span>
        </PrimeButton>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { PurchaseOrderFilters } from "@/model/search/purchase-order-search/PurchaseOrderFilters";
import { Warehouse } from "@/repository/warehouse/model/Warehouse";
import ActiveFilters from "../ActiveFilters.vue";
import { useI18n } from "vue-i18n";

const { t } = useI18n();

const props = defineProps<{
  showSidebar: boolean;
  filters: PurchaseOrderFilters;
  warehouses: Warehouse[];
}>();

const emit = defineEmits<{
  (e: "onToggleFilterSidebar"): void;
  (e: "update:filters", value: PurchaseOrderFilters): void;
}>();

const filtersComputed = computed({
  get: () => {
    return props.filters;
  },
  set: (value: PurchaseOrderFilters) => {
    emit("update:filters", value);
  },
});
</script>
