<template>
  <ul class="c-chips-container" v-if="hasActiveFilters" data-testid="c-active-filters-container">
    <li class="c-chips-clear-filter">
      <span data-testid="c-clear-active-filters-label">{{ t("search.clear-filter") }}</span>
      <span
        class="c-chips-token-icon pi pi-times-circle"
        @click="clearFilters"
        data-testid="c-clear-active-filters-btn"
      >
      </span>
    </li>

    <ActiveFilterChip
      v-if="filters.fromDate !== null"
      :filterValue="d(filters.fromDate)"
      filterType="fromDate"
      @removeFilter="removeFilter"
      dataTestId="c-active-filters-from-date"
    />

    <ActiveFilterChip
      v-if="filters.toDate !== null"
      :filterValue="d(filters.toDate)"
      filterType="toDate"
      @removeFilter="removeFilter"
      dataTestId="c-active-filters-to-date"
    />

    <ActiveFilterChip
      v-if="filters.warehouseIds.length > 0"
      :filterValue="warehouseNamesComputed"
      filterType="warehouseIds"
      @removeFilter="removeFilter"
      dataTestId="c-active-filters-warehouse-id"
    />

    <ActiveFilterChip
      v-if="filters.purchaseOrderStatuses.length > 0"
      :filterValue="filters.purchaseOrderStatuses.map((status) => t(`status.${status.toLowerCase()}`))"
      filterType="purchaseOrderStatuses"
      @removeFilter="removeFilter"
      dataTestId="c-active-filters-goods-reception-status"
    />

    <ActiveFilterChip
      v-if="filters.supplierNumbers.length > 0"
      :filterValue="filters.supplierNumbers"
      filterType="supplierNumbers"
      @removeFilter="removeFilter"
      dataTestId="c-active-filters-supplier-number"
    />

    <ActiveFilterChip
      v-if="filters.productNumbers.length > 0"
      :filterValue="filters.productNumbers"
      filterType="productNumbers"
      @removeFilter="removeFilter"
      dataTestId="c-active-filters-product-number"
    />

    <ActiveFilterChip
      v-if="filters.purchaseOrderNumber.length > 0"
      :filterValue="filters.purchaseOrderNumber"
      filterType="purchaseOrderNumber"
      @removeFilter="removeFilter"
      dataTestId="c-active-filters-purchase-order-number"
    />

    <ActiveFilterChip
      v-if="filters.supplierProductNumbers.length > 0"
      :filterValue="filters.supplierProductNumbers"
      filterType="supplierProductNumbers"
      @removeFilter="removeFilter"
      dataTestId="c-active-filters-supplier-product-number"
    />
  </ul>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { PurchaseOrderFilters } from "@/model/search/purchase-order-search/PurchaseOrderFilters";
import { Warehouse } from "@/repository/warehouse/model/Warehouse";
import ActiveFilterChip from "./ActiveFilterChip.vue";

const props = defineProps<{
  filters: PurchaseOrderFilters;
  warehouses: Warehouse[];
}>();

const emit = defineEmits<{
  (event: "update:filters", value: PurchaseOrderFilters): void;
}>();

const { t, d } = useI18n();

const removeFilter = (filterType: keyof PurchaseOrderFilters | string, index: number) => {
  const filters = { ...props.filters };
  const filterToRemove = filters[filterType as keyof PurchaseOrderFilters];

  if (Array.isArray(filterToRemove)) {
    filterToRemove.splice(index, 1);
  } else if (filterType === "fromDate" || filterType === "toDate") {
    filters[filterType as "fromDate" | "toDate"] = null;
  }

  emit("update:filters", filters);
};

const warehouseNamesComputed = computed<string[]>(() => {
  return props.filters.warehouseIds.map((id) => {
    const warehouse = props.warehouses.find((w) => w.id === id);
    return warehouse?.name ?? "";
  });
});

const hasActiveFilters = computed<boolean>(() => {
  return (
    props.filters.purchaseOrderStatuses.length > 0 ||
    props.filters.productNumbers.length > 0 ||
    props.filters.supplierNumbers.length > 0 ||
    props.filters.supplierProductNumbers.length > 0 ||
    props.filters.warehouseIds.length > 0 ||
    props.filters.fromDate !== null ||
    props.filters.toDate !== null
  );
});

const clearFilters = () => {
  const filters = new PurchaseOrderFilters();
  emit("update:filters", filters);
};
</script>

<style scoped lang="scss">
.c-chips-clear-filter {
  padding: 0.25rem 0.5rem;
  margin-right: 0.5rem;
  color: var(--primary-color-text);
  border-radius: 1rem;
  background-color: var(--chips-bg-primary);
  display: inline-flex;
  align-items: center;
}

.c-chips-container {
  padding: 0.25rem 0.5rem;
  margin: 0;
  overflow: hidden;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.c-chips-token-icon {
  margin-left: 0.5rem;
  cursor: pointer;
}
</style>
