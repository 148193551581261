import { useAuth } from "@cumulus/event-bus";
import { PurchaseOrder } from "@/repository/purchase-order/model/PurchaseOrder";
import { purchaseApi } from "./PurchaseOrderApi";
import { useErrorHandler } from "../ErrorHandler";

export function usePurchaseOrder() {
  const { handleError } = useErrorHandler();
  const { getAuthHeaders } = useAuth();

  const getPurchaseOrder = async (id: string): Promise<PurchaseOrder> => {
    try {
      const authHeaders = await getAuthHeaders();
      return await purchaseApi.get(authHeaders, id);
    } catch (error) {
      await handleError(error);
      throw error;
    }
  };

  return {
    getPurchaseOrder,
  };
}
