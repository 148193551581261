<template>
  <Popover ref="op" data-testid="sort-list">
    <div class="flex flex-col gap-4 w-[14rem] p-2">
      <div>
        <span class="font-bold block mb-2 underline">{{ t("common.sorting-by") }} </span>
        <ul class="list-none p-2 m-0">
          <li
            v-for="column in props.searchColumns"
            :key="column.field"
            class="flex px-2 py-3 hover:bg-emphasis cursor-pointer rounded-border"
            :class="[
              'transition-colors duration-200',
              {
                'bg-primary-50 dark:bg-primary-400/10': isSelectedColumn(column),
                'hover:bg-emphasis': !isSelectedColumn(column),
              },
            ]"
            @click="emit('onSelectedRow', column)"
          >
            <div class="flex-1">
              <span class="font-medium">{{ t(column.header) }}</span>
            </div>
            <div class="text-right">
              <i v-if="isSelectedColumn(column)" :class="selectedIcon"></i>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </Popover>
</template>
<script setup lang="ts">
import type { DataTableColumn } from "@cumulus/components";
import { ref } from "vue";
import { useI18n } from "vue-i18n";

const { t } = useI18n();

const props = defineProps<{
  isSelectedColumn: (column: DataTableColumn) => boolean;
  searchColumns: DataTableColumn[];
}>();

const selectedIcon = defineModel<string>("selectedIcon", { default: "" });

const emit = defineEmits<{
  (e: "onSelectedRow", value: DataTableColumn): void;
}>();

const op = ref();
defineExpose({
  toggle: (event: Event) => op.value.toggle(event),
});
</script>
