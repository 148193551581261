export const PurchaseOrderLineColumns = {
  SelectPurchaseOrderLines: "selectPurchaseOrderLines",
  ExpanderHandle: "expanderHandle",
  PositionNumber: "positionNumber",
  ProductNumber: "product.productNumber",
  SupplierProductNumber: "product.supplierProductNumber",
  Gtin: "product.gtin",
  ProductName: "product.name",
  Quantity: "quantity",
  ReceivedQuantity: "receivedQuantity",
  EstimatedArrivalDate: "estimatedArrivalDate",
  QuantityToReceive: "quantityToReceive",
  Reference: "reference",
  PurchasePrice: "purchasePrice",
  ReceivedCost: "receivedCost",
  SumLine: "sumLine",
  EditHandle: "editHandle",
} as const;
