<template>
  <FloatLabelInput
    id="currency"
    dataTestId="purchase-order-currency"
    v-model:value="currencyComputed"
    :label="t(`purchase.currency`)"
    :placeholder="t('placeholder.select', { property: t('purchase.currency').toLowerCase() })"
    :selectAllOnFocus="true"
    :disabled="true"
    class="p-disabled"
  />
</template>

<script setup lang="ts">
import { computed } from "vue";
import { useI18n } from "vue-i18n";

const { t } = useI18n();

const props = defineProps<{
  currencyIso: string;
}>();

const emit = defineEmits<{
  (e: "update:currencyIso", value: string): void;
}>();

const currencyComputed = computed<string>({
  get: () => {
    return props.currencyIso ?? "";
  },
  set: (value) => {
    emit("update:currencyIso", value);
  },
});
</script>
