import { Currency } from "@/repository/currency/model/Currency";
import { Ref } from "vue";

export function usePriceFunctions(currencies: Ref<Currency[]>) {
  const calculateTotalSum = (value: number, quantity: number) => {
    return value * quantity;
  };

  const calculatePriceInCurrency = (price: number, baseCurrency: string, currencyIso: string) => {
    if (baseCurrency === currencyIso) {
      return price;
    }

    const currency = currencies.value.find((c) => c.currencyIso === currencyIso);
    if (!currency || currency.unit <= 0) {
      return 0;
    }

    return (price * currency.buyRate) / currency.unit;
  };

  return {
    calculateTotalSum,
    calculatePriceInCurrency,
  };
}
