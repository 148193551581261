import { PurchaseOrderLineToReceive } from "./PurchaseOrderLineToReceive";

export class GoodsReceptionLine {
  public purchaseOrderLineId = "";
  public receivedQuantity = 0;
  public productId = "";
  public reference = "";
  public purchasePrice = 0;
  public receivedCost = 0;
  public batchNumber = 0;

  public fromPurchaseOrderLineToReceive(purchaseOrderLineToReceive: PurchaseOrderLineToReceive) {
    this.purchaseOrderLineId = purchaseOrderLineToReceive.id;
    this.receivedQuantity = purchaseOrderLineToReceive.quantityToReceive;
    this.purchasePrice = purchaseOrderLineToReceive.purchasePrice;
    this.productId = purchaseOrderLineToReceive.product.id;
    this.reference = purchaseOrderLineToReceive.reference;
    this.purchasePrice = purchaseOrderLineToReceive.purchasePrice;
    this.receivedCost = purchaseOrderLineToReceive.receivedCost;
    return this;
  }
}
